<template>
  <Layout>
    <ModalFirstAccess />
    <ModalTermsOfUse />
    <!-- CAROUSEL DE BANNERS -->
    <keep-alive>
      <section id="banner-header" class="play-bannerHeader">
        <template>
          <transition>
            <header class="play-bannerCarousel">
              <BannerCarousel
                :data="getHomeData && getHomeData?.banners_top"
                :loading="getIsLoading"
                :emptyState="true"
              />
            </header>
          </transition>
        </template>
      </section>
    </keep-alive>

    <!-- CONTROLE DO ADMINISTRADOR -->
    <keep-alive>
      <template v-if="!getIsMobile && getIsAdmin">
        <section class="play-adminControls" data-testid="admin-controls">
          <AdminBar />
        </section>
      </template>
    </keep-alive>

    <!-- HOME -->
    <div id="home" data-testid="home" class="play">
      <!-- HISTÓRICOS DE VIDEOS -->
      <!-- <transition>
        <template>
          <section class="play-recentViews mb-4" data-testid="recents-view">
            <RecentViews
              :data="getHomeData && getHomeData.courses"
              :hideDisabledCourses="getHideDisabledCourses"
              :loading="getIsLoading"
            />
          </section>
        </template>
      </transition> -->

      <template>
        <ContinueLearning />
      </template>

      <!-- LISTA DE CATEGORIAS -->
      <template v-if="getEnabledCategory">
        <section
          class="play-categories"
          data-testid="categories"
        >
          <div
            class="categories_title"
            :class="{ 'skeleton skeleton-dark': getIsLoading }"
          >
            Escolha uma categoria
          </div>
          <CategoriesNav
            :data="getHomeData?.categories"
            :loading="getIsLoading"
          />
        </section>
      </template>

      <main class="play-videos">
        <!-- VISUALIZAÇÃO POR CURSOS -->
        <template v-if="getCategoryViewMode">
          <CourseViewMode
            :data="getHomeData"
            :hideDisabledCourses="getHideDisabledCourses"
            :loading="getIsLoading"
          >
            <template
              v-slot:normal="{ data }"
              v-if="!getModeCarousel || getRearrangeCourse"
            >
              <CourseNormal
                v-if="data"
                :showRating="getShowRating"
                :data="data"
                :lastPage="getHomeData?.courses_last_page"
                :poster="getMovieCoverMode"
                :loading="getIsLoading"
              />
            </template>
            <template v-slot:normal="{ data }" v-else>
              <CourseCarousel
                v-if="data"
                :loading="getIsLoading"
              />
            </template>
          </CourseViewMode>
        </template>

        <!-- VISUALIZAÇÃO POR MÓDULOS -->
        <template v-if="getModuleViewMode">
          <template>
            <ModuleViewMode
              :data="getHomeData && getHomeData.categories"
              :hideDisabledCourses="getHideDisabledCourses"
              :loading="getIsLoading"
            >
              <!-- NORMAL -->
              <template
                v-slot:normal="{ data }"
                v-if="!getModeCarousel && !getIsMobile"
              >
                <ModuleNormal
                  :showRating="getShowRating"
                  :data="data"
                  :poster="getMovieCoverMode"
                  :loading="getIsLoading"
                />
              </template>
              <!-- CAROUSEL -->
              <template
                v-slot:carousel="{ data }"
                v-if="getModeCarousel || getIsMobile"
              >
                <ModuleCarousel
                  :showRating="getShowRating"
                  :data="data"
                  :poster="getMovieCoverMode"
                  :loading="getIsLoading"
                />
              </template>
            </ModuleViewMode>
          </template>
        </template>

        <!-- CAROUSEL DE BANNERS -->
        <section id="banner-bottom" class="play-bannerBottom">
          <template>
            <transition>
              <header class="play-bannerCarousel">
                <BannerCarousel
                  :data="getHomeData && getHomeData?.banners_bottom"
                  :loading="getIsLoading"
                />
              </header>
            </transition>
          </template>
        </section>
      </main>
    </div>
  </Layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Layout: () =>
      import(/* webpackChunkName: "LayoutHomePlay" */ "@/layout/Layout.vue"),
    BannerCarousel: () =>
      import(
        /* webpackChunkName: "BannerCarousel" */ "@/components/common/carousel/BannerCarousel.vue"
      ),
    /* View By Course */
    CourseViewMode: () =>
      import(
        /* webpackChunkName: "CourseViewMode" */ "@/components/pages/home/viewByCourse/CourseViewMode.vue"
      ),
    CourseNormal: () =>
      import(
        /* webpackChunkName: "CourseNormal" */ "@/components/pages/home/viewByCourse/Normal.vue"
      ),
    CourseCarousel: () =>
      import(
        /* webpackChunkName: "CourseCarousel" */ "@/components/pages/home/viewByCourse/Carousel.vue"
      ),
    /* View By Module */
    ModuleViewMode: () =>
      import(
        /* webpackChunkName: "ModuleViewMode" */ "@/components/pages/home/viewByModule/ModuleViewMode.vue"
      ),
    ModuleNormal: () =>
      import(
        /* webpackChunkName: "ModuleNormal" */ "@/components/pages/home/viewByModule/Normal.vue"
      ),
    ModuleCarousel: () =>
      import(
        /* webpackChunkName: "ModuleCarousel" */ "@/components/pages/home/viewByModule/Carousel.vue"
      ),
    /* Admin */
    AdminBar: () =>
      import(
        /* webpackChunkName: "AdminBar" */ "@/components/pages/home/admin/AdminBar.vue"
      ),
    // RecentViews: () => import("@/components/pages/home/RecentViews.vue"),
    CategoriesNav: () =>
      import(
        /* webpackChunkName: "CategoriesNav" */ "@/components/common/CategoriesNav.vue"
      ),
    
    ModalTermsOfUse: () => import("@/components/ModalTermsOfUse.vue"),
    ContinueLearning: () => import("@/components/common/ContinueLearningNav.vue"),
    ModalFirstAccess: () => import("@/components/ModalFirstAccess.vue")
  },
  data() {
    return {
      modalContent: {
        cover: {},
        title: null,
        description: null,
        button: null,
        url: null
      },
    };
  },
  mounted() {
    document.body.style.backgroundImage = "";
  },
  methods: {
    ...mapActions({
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
    }),

    openModalBuyCourse(item) {
      this.actionShowModalBuyCourse({
        active: true,
        data: item,
      });
    },

    async openModal(item) {
      this.actionShowModalInfoCourse({
        active: true,
        date: item,
      });
    },

  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      getSettings: "config/getSettings",
      getModuleViewMode: "config/getModuleViewMode",
      getEnabledCategory: "config/getEnabledCategory",
      getCategoryViewMode: "config/getCategoryViewMode",
      getMovieCoverMode: "config/getMovieCoverMode",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsMobile: "layout/getIsMobile",
      getModeCarousel: "config/getModeCarousel",
      getRearrangeCourse: "home/getRearrangeCourse",
      getShowRating: "config/getShowRating",
      getIsLoading: "config/getIsLoading",
      getAllowRequest: "config/getAllowRequest",
      getHomeData: "home/getHomeData",
      getAllCategories: "home/getAllCategories",
      getModalInfoCourse: "home/getModalInfoCourse",
      getModalBuyCourse: "home/getModalBuyCourse",
      getIsAdmin: "config/getIsAdmin",
      getUserMetas: "user/getMetas",
      getCurrentSite: "user/currentSite"
    }),
  },
};
</script>

<style lang="scss" scoped>
.categories_title {
  color: var(--fontcolor);
  @extend .body-semibold-14;
}

.play {
  padding: 16px;

  &-footer {
    height: 70px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 769px) {
    padding: var(--spacing-24) var(--spacing-36);
  }
}
</style>
